html,body,#root,.App {
    height: 100%;
}

.App {
  text-align: center;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.sidebar {
    background-color: darkgray;
    height: 100%;
    padding-top: 12px;
    flex: auto;
    max-width: 240px;
}

.main {
    background-color: lightgray;
    flex: auto;
    height: 100%;
}